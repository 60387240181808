// import { convertNullIfEmpty as convNull } from "@/utils/null-check";
// import cloneDeep from "lodash/cloneDeep";

class LeadModel {
    payload;

    constructor(payload) {
        this.payload = payload;
    }
    toCreatePayload() {
        let {
            name,
            contact,
            propertyCategory,
            propertyTypeId,
            state,
            postcode,
            address,
            purpose
        } = this.payload;

        return {
            name: name || "",
            contact: contact || "",
            propertyCategory: propertyCategory || null,
            propertyTypeId: propertyTypeId || "",
            state: state || "",
            postcode: postcode || "",
            address: address || "",
            purpose: purpose || ""
        };
    }
}

export default LeadModel;
