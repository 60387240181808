<template>
  <div class="create-lead">
    <div class="container fluid">
      <div class="d-flex align-items-center py-3">
        <fd-button class="circle mr-2" @click="$router.go(-1)">
          <i class="fas fa-chevron-left"></i>
        </fd-button>
        <h1>Create Lead</h1>
      </div>

      <div class="card p-3">
        <fd-form @submit="createLead">
          <fd-form-section title="Contact Person">
            <fd-input
              v-model="form.name"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Name"
              name="contactPersonName"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <fd-input
              v-model="form.contact"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Contact"
              name="contactPersonContact"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
          </fd-form-section>

          <fd-form-section title="Property">
            <fd-select
              v-model="form.purpose"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Purpose"
              name="leadPropertyPurpose"
              :options="purposeOptions"
              :optionLabel="(val) => val"
              :optionValue="(val) => val"
              :validators="[validator.required]"
            >
            </fd-select>
            <fd-select
              v-model="form.propertyCategory"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Property Category"
              name="leadPropertyCategory"
              :options="propertyCategoryOptions"
              :optionLabel="(val) => val"
              :optionValue="(val) => val"
              :validators="[validator.required]"
              @input="getPropertyTypes"
            >
            </fd-select>
            <fd-select
              v-model="form.propertyTypeId"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Property Type"
              name="leadPropertyType"
              :options="propertyTypeOptions"
              :optionLabel="(val) => val.name"
              :optionValue="(val) => val.id"
              :validators="[validator.required]"
              :disabled="form.propertyCategory == ''"
              :isLoading="isPropertyTypeLoading"
            >
            </fd-select>
          </fd-form-section>
          <fd-form-section title="Address">
            <fd-input
              v-model="form.address"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Address"
              name="leadAddress"
              type="text"
            >
            </fd-input>
            <fd-input
              v-model="form.state"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="State"
              name="leadState"
              type="text"
            >
            </fd-input>
            <fd-input
              v-model="form.postcode"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Postcode"
              name="leadPostcode"
              type="text"
            >
            </fd-input>
          </fd-form-section>

          <fd-button
            type="submit"
            class="fixed round medium main"
            icon="fas fa-plus"
            >Create Lead</fd-button
          >
        </fd-form>
      </div>
    </div>
  </div>
</template>

<script>
import { propertyCategory, purpose } from "@/enums";
import { getPropertyTypes } from "@/modules/Property/api/property";
import { lead as LeadAPI } from "@/api";
import LeadModel from "@/modules/Lead/models/LeadModel";
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {
      form: {
        name: "",
        contact: "",
        propertyCategory: "",
        propertyTypeId: "",
        state: "",
        postcode: "",
        address: "",
        purpose: ""
      },

      purposeOptions: this.$mapJsonToArray(purpose, (e) => e).filter(
        (e) => e != "Auction"
      ),
      propertyCategoryOptions: this.$mapJsonToArray(propertyCategory, (e) => e),
      propertyTypeOptions: [],
      isPropertyTypeLoading: false,

      // Validator
      validator: {
        required
      },

      // Enums
      purpose,
      propertyCategory
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    async getPropertyTypes(val) {
      let queries = {
        propertyCategory: val
      };
      try {
        this.isPropertyTypeLoading = true;
        let data = await getPropertyTypes({ queries });
        this.propertyTypeOptions = data;
        this.isPropertyTypeLoading = false;
      } catch (error) {
        this.isPropertyTypeLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch property type options. Please try again later."
        });
      }
    },
    async createLead() {
      try {
        this.$store.commit("setIsLoading", true);
        let payload = new LeadModel(this.form).toCreatePayload();
        await LeadAPI.createLead(payload);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Lead Created",
          text: "New lead has been created successfully."
        });
        this.$router.push({ name: "Leads" });
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to create lead. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
